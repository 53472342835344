import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"

const Page = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Migrating from Specpack practice management system"
        pathname="/migrating-from-specpak"
        description="If you are thinking of migrating from the Specpack practice management software to Carebit, we can help you move all your data across."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                New customers
              </Badge>
              <h1>Migrating from Specpack to Carebit</h1>
              <p className="u-whiteTranslucent">
                We can help you migrate your patient records, and provide
                reassurance around the long-term management of your data.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 12 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col md={{ size: 6 }} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Seamless migration">
                        Modernise your practice by moving to Carebit
                      </Heading>
                    </Row>
                    <p>
                      We have successfully helped several private practices to
                      swiftly and seamlessly switch from Specpack to Carebit,
                      transferring over 30,000 patient records in the process.
                      We do everything for you too - all you have to do is
                      provide your Specpack files by simply copying them off
                      your computer.
                    </p>
                    <p>
                      All key patient data is all brought across, including
                      letters, scans, demographic data, bookings, invoices and
                      payments. Typically the whole process can be done in just
                      a few hours, minimising your downtime.
                    </p>

                    <p>
                      For more information, please get in touch with us using
                      the form below.
                    </p>
                  </Col>

                  <Col
                    lg={{ size: 6 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  ></Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Page
